import React, { useEffect, useState } from 'react';

// import bgimage from "../../assets/imges/AIAgentSolutions.png"
// import availability from "../../assets/imges/AIAgentSolutions2.png"
// import empowerimage from "../../assets/imges/empowerimage.png"
import Relume from "../../assets/imges/Relume.png"
// import Stars from "../../assets/imges/Stars.png"
// import qutosImage from "../../assets/imges/OurTeam.png"
// import WebFlow from "../../assets/imges/WebFlow.png"
// import PlaceholderRectangle from "../../assets/imges/PlaceholderRectangle.png"
import RavenBlack from "../../assets/imges/RavenBlackLogo.png"
import LogoBlack from "../../assets/LogoBlack.png"
import RavenBlack_w_616_h_640 from "../../assets/imges/RavenBlack_w_616_h_640.png"
import { useNavigate } from "react-router-dom";
import MyConstant from '../../config/MyConstant';




import "./style.css";

const AIAgentSolutions = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(0);

    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    function checkMobile() {
        return windowWidth < 500
    }
    return <div className='ai-agent-solutions'>
        <div className={`p-${checkMobile() ? 3 : 5} header1 d-flex align-items-center`} style={{ backgroundColor: "#000" }}>
            <div className='d-flex w-100'>
                <div className='flex-1 d-flex flex-column justify-content-center'>
                    <h1>AI Agent Solutions</h1>
                    <div className={`w-${checkMobile() ? 100 : 50}`}>
                        Explore how Raven AI transforms communication with advanced AI Agents for seamless customer interactions.
                    </div>
                </div>
                <div className='flex-1 text-center'>
                    <img className='img-fluid' src={RavenBlack} />
                </div>
            </div>

        </div>
        <div className={`mx-${checkMobile() ? 3 : 5}`}>
            <div className={"row py-5"}>
                <div className='col-sm-6 mb-3'>
                    <div className='text-center'>
                        <img src={LogoBlack} className='img-fluid img-bg-clr' alt="img" />
                    </div>
                </div>

                <div className='col-sm-6 mb-3'>
                    <div className={`d-flex flex-column justify-content-center h-100`}>
                        <h5>Empower</h5>
                        <h1>Streamline Your AI Agent Deployment Effortlessly</h1>
                        <p>Our AI Platform simplifies the deployment and management of AI Agents, allowing businesses to enhance customer interactions seamlessly. With robust features and intuitive controls, you can easily integrate AI into your communication processes.</p>

                        <div className=''>
                            {/* <button className='btn btn-learn-more'>Learn More</button> */}
                            <button className='btn btn-learn mx-2' onClick={() => {
                                navigate(MyConstant.keyList.projectUrl + "/contact-us");
                            }}>Contact Us </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"row py-5"}>
                <div className='offset-sm-3 col-sm-6'>
                    <div className='text-center'>
                        <h1>Streamlined Communication: Enhance interactions between customers and agents effortlessly.</h1>
                    </div>
                </div>
            </div>
            <div className={"row py-5"}>
                <div className='col-sm-4 d-flex justify-content-center'>
                    <div className='box-setup'>
                        <div className='text-center'>
                            <img src={LogoBlack} className='img-fluid img-bg-clr' alt={""}></img>
                        </div>
                        <div className='text-center'>
                            <div className='my-3'>
                                <b>24/7 Availability: Our AI Agents are always ready to assist your customers.</b>
                            </div>
                            <div className='my-3'>
                                With multilingual support, our agents cater to a global audience.
                            </div>
                            {/* <button className='btn btn-join-us mx-2'>Learn More <i className='mx-2'><i className='fa fa-chevron-right'></i></i></button> */}
                        </div>
                    </div>
                </div>
                <div className='col-sm-4 d-flex justify-content-center'>
                    <div className='box-setup'>
                        <div className='text-center'>
                            <img src={LogoBlack} className='img-fluid img-bg-clr' alt={""}></img>
                        </div>
                        <div className='text-center'>
                            <div className='my-3'>
                                <b>Automated Transactions: Simplifying processes for cancellations, confirmations, and inquiries.</b>
                            </div>
                            <div className='my-3'>
                                Our platform ensures seamless handling of various customer interactions.
                            </div>


                        </div>
                    </div>
                </div>

                <div className='col-sm-4 d-flex justify-content-center'>
                    <div className='box-setup'>
                        <div className='text-center'>
                            <img src={LogoBlack} className='img-fluid img-bg-clr' alt={""}></img>
                        </div>
                        <div className='text-center'>
                            <div className='my-3'>
                                <b>Traceability and Audit: Every interaction is recorded for accountability.</b>
                            </div>
                            <div className='my-3'>
                                Our platform provides detailed logs for transparency and compliance.
                            </div>
                            {/* <button className='btn btn-join-us mx-2'>Discover <i className='mx-2'><i className='fa fa-chevron-right'></i></i></button> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-center'>
                <button className='btn btn-learn mx-2' onClick={() => {
                    navigate(MyConstant.keyList.projectUrl + "/contact-us");
                }}>Contact Us </button>
            </div>
            <div className={"row py-5"}>
                <div className='col-sm-6'>
                    <div className={`d-flex flex-column justify-content-center h-100`}>
                        <h1>Empowering Communication: AI Agents Bridging the Gap Between Customers and Agents</h1>
                        <p>AI Agents play a crucial role in enhancing communication by efficiently resolving customer inquiries and issues. They facilitate seamless interactions, ensuring that both customers and human agents can connect and collaborate effectively.</p>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='text-center'>
                        <img src={RavenBlack_w_616_h_640} className='img-fluid' alt="img" />
                    </div>
                </div>
            </div>
            <div className={"row py-5"}>
                <div className='col-sm-6'>
                    <h1>Discover the Seamless Integration of AI Agents in Your Business Operations</h1>
                </div>
            </div>
            <div className={"row py-5"}>
                <div className='col-sm-4'>
                    <img src={Relume} className='img-fluid' alt="" />
                    <div className='my-3'>
                        <h2>Transforming Customer Interactions with Intelligent AI Mediation</h2>
                    </div>
                    <div className='my-3'>
                        Our AI Agents streamline communication by efficiently managing interactions between customers and human agents.
                    </div>
                    {/* <button className='btn btn-join-us'>Learn More <i className='mx-2'><i className='fa fa-chevron-right'></i></i></button> */}
                </div>
                <div className='col-sm-4'>
                    <img src={Relume} className='img-fluid' alt="" />
                    <div className='my-3'>
                        <h2>From Deployment to Interaction Management: The AI Agent Journey</h2>
                    </div>
                    <div className='my-3'>
                        Once deployed, AI Agents autonomously handle inquiries, ensuring timely and accurate responses.
                    </div>

                </div>
                <div className='col-sm-4'>
                    <img src={Relume} className='img-fluid' alt="" />
                    <div className='my-3'>
                        <h2>Ensuring Accountability: Traceability and Audit in AI Interactions</h2>
                    </div>
                    <div className='my-3'>
                        Every interaction is recorded, providing a comprehensive audit trail for transparency.
                    </div>
                    {/* <button className='btn btn-join-us'>Learn More <i className='mx-2'><i className='fa fa-chevron-right'></i></i></button> */}
                </div>
            </div>

            <div className='text-center mb-5'>
                <button className='btn btn-learn' onClick={() => {
                    navigate(MyConstant.keyList.projectUrl + "/contact-us");
                }}>Contact Us</button>
            </div>

            {/* <div className='row py-5'>
                <h1>Customer Testimonials</h1>
                <div className='my-3'>
                    Raven AI transformed our customer service experience!
                </div>
            </div> 

            <div className='row py-5'>
                <div className='col-sm-6 px-5'>
                    <div className='my-3'>
                        <img src={Stars} className='img-star-icon' alt="img" />
                    </div>
                    <div className='my-3'>
                        <b>"The AI agents have streamlined our operations and improved efficiency. Our customers are happier than ever!"</b>
                    </div>
                    <div className='my-3'>
                        <div className='customer-review d-flex align-items-center'>
                            <img src={qutosImage} className='img-fluid' alt="img" />
                            <div>
                                <b>Jane Doe</b>
                                <div>CEO, Tech Solutions</div>
                            </div>
                            <div className='divider-box m-hide'></div>
                            <div style={{maxWidth: 120}}>
                                <img src={WebFlow} className='img-fluid' alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6 px-5'>
                    <div className='my-3'>
                        <img src={Stars} className='img-star-icon' alt="img" />
                    </div>
                    <div className='my-3'>
                        <b>"Implementing Raven AI's platform was a game changer for us. The support and results exceeded our expectations!"</b>
                    </div>
                    <div className='my-3'>
                        <div className='customer-review d-flex align-items-center'>
                            <img src={qutosImage} className='img-fluid' alt="img" />
                            <div>
                                <b>John Smith</b>
                                <div>Manager, Retail Corp</div>
                            </div>
                            <div className='divider-box m-hide'></div>
                            <div style={{maxWidth: 120}}>
                                <img src={WebFlow} className='img-fluid' alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            */}
        </div>
        <div className='p-5 header1 d-flex ai-agent-solutions align-items-center' style={{ backgroundColor: "#000" }}>
            <div className='flex-1'>
                <h1>Transform Your Business with AI</h1>
                <div className={`w-${checkMobile ? 100 : 50} my-3`}>
                    Discover how our AI Agents can streamline your operations and enhance customer engagement today.
                </div>
                <div className='d-flex my-3'>
                    {/* <button className='btn btn-contact'>Contact</button>
                    <button className='btn btn-schedule mx-2'>Schedule</button> */}
                    <div className='learn-more-btn d-flex align-items-center justify-content-center c-poiner' onClick={() => {
                        navigate(MyConstant.keyList.projectUrl + "/contact-us");
                    }}>Contact Us</div>
                </div>
            </div>
            <div className='flex-1 text-center'>
                <img src={RavenBlack} className='image img-fluid' alt="unlock-power img"/>
            </div>
        </div>
        {/* <div className='mx-5'>
            <div className={`row py-5`}>
                <div className='col-sm-12'>
                    <b>Connect</b>
                    <h1>Get in Touch</h1>
                    <div className='my-3'>
                        We're here to assist you with your inquiries.
                    </div>
                </div>
            </div>
            <div className={`row py-5 contact-us`}>
                <div className='col-sm-4'>
                    <div>
                        <i className="far fa-envelope"></i>

                        <div className='my-2'>
                            <b>Email</b>
                        </div>
                        <div className='my-2'>
                            Reach us anytime at
                        </div>
                        <div className='my-2'>
                            <a href="mailto:hello@ravenai.com">hello@ravenai.com</a>
                        </div>
                    </div>
                    <div className='my-5'>
                        <i className="fas fa-phone-alt"></i>

                        <div className='my-2'>
                            <b>Phone</b>
                        </div>
                        <div className='my-2'>
                            Call us at
                        </div>
                        <div className='my-2'>
                            <a href="tel:+15551234567">+1 (555) 123-4567</a>
                        </div>
                    </div>
                    <div className='my-5'>
                        <i className="fas fa-map-marker-alt"></i>

                        <div className='my-2'>
                            <b>Office</b>
                        </div>
                        <div className='my-2'>
                            456 Innovation Way, San Francisco, CA 94105
                        </div>
                        <div className='my-2'>
                            <a href="">Get Directions</a>
                        </div>
                    </div>
                </div>
                <div className='col-sm-8'>
                    <img src={PlaceholderRectangle} className='img-fluid img-bg-clr' alt="img" />
                </div>
            </div>
        </div> */}
    </div>;
};

export default AIAgentSolutions;