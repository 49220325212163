import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import { getGrpcClient, getUuidv4 } from '../config/Common';
import GlobalMsgCons from '../config/GlobalMsgCons';
import DropDown from "../components/DropDown";

const subjectInquiryOptions = [{ label: "Sales Inquiry", value: "Sales Inquiry" }, { label: "Technical Support", value: "Technical Support" }, { label: "General Questions", value: "General Questions" }]

const ContactUs = (props) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    let [fullName, setFullName] = useState("");
    let [companyName, setCompanyName] = useState("");
    let [emailAddress, setEmailAddress] = useState("");
    let [phoneNumber, setPhoneNumber] = useState("");
    let [messageInquiry, setMessageInquiry] = useState("");
    let [subjectInquiry, setSubjectInquiry] = useState("");
    let [errors, seterrors] = useState({});
    let [submitLoader, setSubmitLoader] = useState(false);
    let [selectBoxValidate, setSelectBoxValidate] = useState(false);



    function submitForm() {
        let mailValidate = /^[a-zA-Z0-9._%'+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        errors = {}
        if (fullName.trim() === '') {
            errors["fullName"] = "Please Enter Full Name."
        }

        if (emailAddress.trim() === '') {
            errors["emailAddress"] = "Please Enter Email Address."
        }
        else if (mailValidate.test(emailAddress) === false) {
            errors["emailAddress"] = "Please enter valid email.";
        }

        if (messageInquiry.trim() === '') {
            errors["messageInquiry"] = "Please Enter Message or Inquiry Field."
        }

        if (subjectInquiry.trim() === '') {
            errors["subjectInquiry"] = "Please Choose Subject of Inquiry."
        }
        setSelectBoxValidate(true)
        seterrors(errors)
        if (Object.keys(errors).length === 0) {
            setSubmitLoader(true)
            let postJson = { reqId: getUuidv4(), name: fullName, companyName: companyName, email: emailAddress.toLowerCase(), mobileNo: phoneNumber, subject: subjectInquiry, message: messageInquiry };
            // console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain("ravenai");
            request.setEvent("contactUs");
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    if (responseData.response === GlobalMsgCons.success) {
                        setFullName("")
                        setCompanyName("")
                        setEmailAddress("")
                        setPhoneNumber("")
                        setMessageInquiry("")
                        setSubjectInquiry("")
                        setSubmitLoader(false)
                        setSelectBoxValidate(false)
                        toast.success("Email sent successfully", {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            });


        }

    }

    return (<>
        <div className="container">
            <div className="card mt-5">
                <div className="card-header bg-black text-center">
                    <h4 className="text-white pt-2">Contact Us</h4>
                </div>
                <div className="card-body p-4">
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div className="form-group my-3">
                                <label className="font-20">Full Name *</label>
                                <input type="text" className={`form-control form-control-solid form-control-lg ${errors['fullName'] ? "is-invalid" : ""}`} placeholder="Enter Full Name" value={fullName} onChange={(e) => {
                                    setFullName(e.target.value)
                                    if (errors['fullName']) {
                                        delete errors['fullName']
                                        seterrors(errors)
                                    }

                                }} />
                                {errors["fullName"] && <div className="invalid-feedback">
                                    {errors["fullName"]}
                                </div>}
                            </div>
                        </div>

                        <div className="col-sm-6  col-xs-12">
                            <div className="form-group my-3">
                                <label className="font-20">Company Name</label>
                                <input type="text" className={`form-control form-control-solid form-control-lg`} placeholder="Enter Company Name" value={companyName} onChange={(e) => {
                                    setCompanyName(e.target.value)
                                }} />
                            </div>
                        </div>

                        <div className="col-sm-6  col-xs-12">
                            <div className="form-group my-3">
                                <label className="font-20">Email Address *</label>
                                <input type="email" className={`form-control form-control-solid form-control-lg ${errors['emailAddress'] ? "is-invalid" : ""}`} placeholder="Enter Email Address" value={emailAddress} onChange={(e) => {
                                    setEmailAddress(e.target.value)
                                    if (errors['emailAddress']) {
                                        delete errors['emailAddress']
                                        seterrors(errors)
                                    }
                                }} />
                                {errors["emailAddress"] && <div className="invalid-feedback">
                                    {errors["emailAddress"]}
                                </div>}
                            </div>
                        </div>
                        <div className="col-sm-6  col-xs-12">
                            <div className="form-group my-3">
                                <label className="font-20">Phone Number</label>
                                <input type="number" className={`form-control form-control-solid form-control-lg`} placeholder="Enter Phone Number" value={phoneNumber} onChange={(e) => {
                                    setPhoneNumber(e.target.value)
                                }} />
                            </div>
                        </div>
                        <div className="col-sm-6  col-xs-12">
                            <div className="form-group my-3">
                                <label className="font-20">Message or Inquiry Field *</label>
                                <textarea className={`form-control form-control-solid form-control-lg ${errors['messageInquiry'] ? "is-invalid" : ""}`} placeholder="Enter Message or Inquiry Field" value={messageInquiry} onChange={(e) => {
                                    setMessageInquiry(e.target.value)
                                    if (errors['messageInquiry']) {
                                        delete errors['messageInquiry']
                                        seterrors(errors)
                                    }
                                }} rows="3"></textarea>
                                {errors["messageInquiry"] && <div className="invalid-feedback">
                                    {errors["messageInquiry"]}
                                </div>}
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="form-group my-3">
                                <label className="font-20">Subject of Inquiry *</label>
                                {/* <input type="text" className={`form-control form-control-solid form-control-lg ${errors['subjectInquiry'] ? "is-invalid" : ""}`} placeholder="Enter Subject of Inquiry" value={subjectInquiry} onChange={(e) => {
                                    setSubjectInquiry(e.target.value)
                                    if (errors['subjectInquiry']) {
                                        delete errors['subjectInquiry']
                                        seterrors(errors)
                                    }
                                }} /> */}
                                <DropDown
                                    options={subjectInquiryOptions}
                                    isSearchable={true}
                                    placeholder={"Subject of Inquiry"}
                                    onChange={(e) => {
                                        setSubjectInquiry(e.value)
                                        if (errors['subjectInquiry']) {
                                            delete errors['subjectInquiry']
                                            seterrors(errors)
                                        }
                                    }}
                                    selectedOption={subjectInquiryOptions.filter((fe) => { return fe.value === subjectInquiry })}
                                    isDisabled={false}
                                    isMulti={false}
                                    error={selectBoxValidate ? subjectInquiry ? false : true : false}
                                />
                                {errors["subjectInquiry"] && <div className="invalid-feedback d-block">
                                    {errors["subjectInquiry"]}
                                </div>}
                            </div>
                        </div>

                        <div className="form-group my-3 text-center">
                            <button disabled={submitLoader} className="btn btn-join" onClick={() => {
                                submitForm()
                            }}> {submitLoader && <i className="fa fa-spinner fa-spin"></i>}
                                <span className={`${submitLoader && ("pl-1")}`}> Submit</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default ContactUs;
