import { useState, } from "react";
import { ZiFetchRequest } from '../../protobuf/generated/ZiFetchService_grpc_web_pb';
import { getGrpcClient, getUuidv4 } from '../../config/Common';

import { Link } from "react-router-dom";
import "./Footer.css"
import { toast } from 'react-toastify';
import GlobalMsgCons from "../../config/GlobalMsgCons";


const footerLinks = [
    {
        label: "Resources Section",
        child: [
            // {
            //     label: "Blog Articles",
            //     url: "",
            //     linkTarget: ""//_self
            // },
            // {
            //     label: "Case Studies",
            //     url: "",
            //     linkTarget: ""
            // },
            // {
            //     label: "FAQs",
            //     url: "",
            //     linkTarget: ""
            // },
            {
                label: "Contact Us",
                url: "/contact-us",
                linkTarget: ""
            },
            {
                label: "About Us",
                url: "/about",
                linkTarget: ""
            },
        ]
    },
    // {
    //     label: "Support Links",
    //     child: [
    //         {
    //             label: "Help Center",
    //             url: "",
    //             linkTarget: ""
    //         },
    //         {
    //             label: "User Guides",
    //             url: "",
    //             linkTarget: ""
    //         },
    //         {
    //             label: "Community Forum",
    //             url: "",
    //             linkTarget: ""
    //         },
    //         {
    //             label: "Feedback",
    //             url: "",
    //             linkTarget: ""
    //         },
    //         {
    //             label: "System Status",
    //             url: "",
    //             linkTarget: ""
    //         },
    //     ]
    // },
    // {
    //     label: "Company Info",
    //     child: [
    //         {
    //             label: "Careers",
    //             url: "",
    //             linkTarget: ""
    //         },
    //         {
    //             label: "Press Releases",
    //             url: "",
    //             linkTarget: ""
    //         },
    //         {
    //             label: "Privacy Policy",
    //             url: "",
    //             linkTarget: ""
    //         },
    //         {
    //             label: "Terms of Service",
    //             url: "",
    //             linkTarget: ""
    //         },
    //         {
    //             label: "Site Map",
    //             url: "",
    //             linkTarget: ""
    //         },
    //     ]
    // },
    {
        label: "Connect With Us",
        child: [
            {
                label: "LinkedIn Page",
                url: "https://www.linkedin.com/company/raven-ai-agents",
                linkTarget: "_blank"
            },
            {
                label: "Twitter Feed",
                url: "https://x.com/raven_ai_agents",
                linkTarget: "_blank"
            },
            // {
            //     label: "Facebook Group",
            //     url: "",
            //     linkTarget: ""
            // },
            // {
            //     label: "YouTube Channel",
            //     url: "",
            //     linkTarget: ""
            // },
            // {
            //     label: "Instagram Profile",
            //     url: "",
            //     linkTarget: ""
            // },
        ]
    },
    // {
    //     label: "Legal Information",
    //     child: [
    //         {
    //             label: "Cookie Policy",
    //             url: "",
    //             linkTarget: ""
    //         },
    //         {
    //             label: "Accessibility Statement",
    //             url: "",
    //             linkTarget: ""
    //         },
    //         {
    //             label: "GDPR Compliance",
    //             url: "",
    //             linkTarget: ""
    //         },
    //         {
    //             label: "Data Protection",
    //             url: "",
    //             linkTarget: ""
    //         },
    //         {
    //             label: "User Agreement",
    //             url: "",
    //             linkTarget: ""
    //         },
    //     ]
    // },
    // {
    //     label: "Follow Us",
    //     child: [
    //         {
    //             label: "Join Our Community",
    //             url: "",
    //             linkTarget: ""
    //         },
    //         {
    //             label: "Subscribe to Blog",
    //             url: "",
    //             linkTarget: ""
    //         },
    //         {
    //             label: "Get Updates",
    //             url: "",
    //             linkTarget: ""
    //         },
    //         {
    //             label: "Stay Connected",
    //             url: "",
    //             linkTarget: ""
    //         },
    //         {
    //             label: "Join Us",
    //             url: "",
    //             linkTarget: ""
    //         },
    //     ]
    // },
]

export default function Footer({ path, grpcClient }) {
    let [emailAddress, setEmailAddress] = useState("");
    let [errors, seterrors] = useState({});
    let [submitLoader, setSubmitLoader] = useState(false);

    function subscripePost() {

        let mailValidate = /^[a-zA-Z0-9._%'+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        errors = {}
        if (emailAddress.trim() === '') {
            errors["emailAddress"] = "Please Enter Email Address."
        }
        else if (mailValidate.test(emailAddress) === false) {
            errors["emailAddress"] = "Please enter valid email.";
        }
        seterrors(errors)
        if (Object.keys(errors).length === 0) {
            setSubmitLoader(true)

            let postJson = { reqId: getUuidv4(), email: emailAddress.toLowerCase() };
            // console.log("postJson", postJson)
            // return false
            const request = new ZiFetchRequest();
            request.setDomain("ravenai");
            request.setEvent("subscribe");
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    setSubmitLoader(false)
                    if (responseData.response === GlobalMsgCons.success) {
                        setEmailAddress("")
                        toast.success("Subscribed successfully", {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (responseData.response === GlobalMsgCons.alreadyExist) {
                        toast.success("This email address is already subscribed", {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            });

        }
    }
    return <div className="container-fluid footer">
        <div className="row mx-0 my-5">
            <div className="col-sm-8">
                <b>Subscribe to Updates</b>
                <div>Stay informed about our latest AI solutions and updates.</div>
            </div>
            <div className="col-sm-4">
                <div className="row  mr-4">
                    <div className="col-sm-8 col-xs-12 py-2">
                        <div className="form-group">
                            <input type="email" className={`form-control input-border-black-custom ${errors['emailAddress'] ? "is-invalid" : ""}`} value={emailAddress} onChange={(e) => {
                                setEmailAddress(e.target.value)
                                if (errors["emailAddress"]) {
                                    seterrors({})
                                }
                            }} placeholder="Your Email Here" />
                            {errors["emailAddress"] && <div className="invalid-feedback">
                                {errors["emailAddress"]}
                            </div>}
                        </div>
                    </div>
                    <div className="col-sm-4 col-xs-12 py-2">
                        <button className="btn btn-join" disabled={submitLoader} onClick={() => {
                            subscripePost()
                        }}>
                            {submitLoader && <i className="fa fa-spinner fa-spin"></i>}
                            <span className={`${submitLoader && ("pl-1")}`}> Join</span>
                        </button>
                    </div>
                    {/* By subscribing, you accept our Privacy Policy and Terms. <br/> */}
                </div>
                <div>
                    <div className="my-2 row">
                        <Link to={path + "/home"}>By subscribing, you accept our Privacy Policy and Terms.</Link>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div className="row my-5">
            {
                footerLinks.map((column, index) => {
                    let { label, child } = column
                    return <div className="col-sm-2" key={index}>
                        <div className="parent-label"><b>{label}</b></div>
                        {
                            child.map((item, cIndex) => {
                                let { label, linkTarget, url } = item
                                return <div className="my-3" key={cIndex}>
                                    <Link to={path + url} target={linkTarget}>{label}</Link>
                                </div>
                            })
                        }
                    </div>
                })
            }
        </div>
        <hr />
        <div className="row mb-3">
            <div className="col-sm-6">
                <b>Raven AI</b>
            </div>
            <div className="col-sm-6 copyright text-end">
                &copy; {new Date().getFullYear()} Raven AI. All rights reserved.
            </div>
        </div>
    </div>;
}
