import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

// import bgimage from "../assets/HomePage.jpeg"
import bgimage from "../assets/HomePageImgCropping.jpeg"
import TransformCustomer from "../assets/TransformCustomer.jpeg"
import Relume from "../assets/Menu/Relume.png"
import RavenAIWhite from "../assets/RavenAIWhiteCustom.png"
import MyConstant from '../config/MyConstant';
import RavenBlack from "../assets/imges/RavenBlackLogo.png"

const Home = (props) => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(0);

    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    function checkMobile() {
        return windowWidth < 500
    }


    return <>
        <div className='empower-box'>
            <div className='p-5 empower-content header1 d-flex flex-column justify-content-center' style={{ backgroundImage: `url(${bgimage})` }}>
                <div className='content-section'>
                    <h1 className='text-start empower-content-header'>
                        Empower Your Business with AI-Driven Solutions
                    </h1>
                    <p className={`text-start empower-content-sub-text`}>Raven AI transforms customer interactions with intelligent agents that operate 24/7 across multiple channels. Experience seamless support and efficiency that scales with your business needs.</p>
                    <div className='d-flex'>
                        {/* <div className='learn-more-btn d-flex align-items-center justify-content-center mx-3' >Learn More</div> */}
                        <div className='learn-more-btn d-flex align-items-center justify-content-center  c-pointer' onClick={() => {
                            navigate(MyConstant.keyList.projectUrl + "/contact-us");
                        }}>Contact Us</div>
                    </div>
                </div>
            </div>
        </div>



        <div className={`mx-${checkMobile() ? 3 : 5}`}>
            <div className='row py-5'>
                <div className='col-sm-6 col-xs-12  d-flex justify-content-center align-items-center'>
                    <div className='empower-transform-customer-content'>
                        <div className='empower-transform-section-title'>
                            <h5 className='empower-text'>Empower</h5>
                            <h1 className='heading'>Transform Your Customer Experience with AI Agents</h1>
                            <p className='text'>Raven AI Agents are available 24/7, ensuring your customers receive support whenever they need it. With multilingual capabilities, we cater to a global audience, breaking down language barriers.</p>
                        </div>
                        <div className='row empower-transform-section-content'>
                            <div className={`${windowWidth > 580 && windowWidth < 750 ? "col-sm-12" : "col-sm-6"}  col-xs-12`}>
                                <h1 className='heading'>Flexibility</h1>
                                <p className='text'>Human oversight for enhanced decision-making.</p>
                            </div>
                            <div className={`${windowWidth > 580 && windowWidth < 750 ? "col-sm-12" : "col-sm-6"}  col-xs-12`}>
                                <h1 className='heading'>Scalability</h1>
                                <p className='text'>Easily handle increased customer interactions..</p>
                            </div>
                        </div>
                        <div className='d-flex'>
                            {/* <div className='learn-more-btn  d-flex align-items-center justify-content-center ' style={{ marginRight: 10 }}>Learn More</div> */}
                            <div className='d-flex align-items-center justify-content-center'>
                                <div className='btn btn-learn btn-sign-up d-flex align-items-center justify-content-center' onClick={() => {
                                    navigate(MyConstant.keyList.projectUrl + "/contact-us");
                                }}>Contact Us
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6 col-xs-12 my-3'>
                    <div className='text-center'>
                        <img src={TransformCustomer} className='img-fluid' alt="img" />
                    </div>
                </div>
            </div>
        </div>


        <div className={`transform-business-box py-5 mx-${checkMobile() ? 3 : 5}`}>
            <div className='section-title-box'>
                <div className='tag-line-wrapper'>
                    <h5 className='empower-text'>Empower</h5>
                </div>
                <div className='d-flex'>
                    <div className='transform-business-section-title'>
                        <h1 className='heading'>Transform Your Business with AI Agents</h1>
                        <p className='text'>Raven AI Agents revolutionize how businesses interact with customers. Our intelligent solutions streamline operations, enhance communication, and boost efficiency.</p>
                    </div>
                </div>
            </div>
            <div className='content-box'>
                <div className='row py-5'>
                    <div className='col-sm-4 col-md-4 col-xs-12'>
                        <img className="img-fluid relume-img" src={Relume} alt={"Raven AI"} />
                        <h1 className='heading'>Customer Service Automation for Seamless Support</h1>
                        <div className='content-title'>
                            <p className='text'>Automate customer inquiries and support with our AI Agents, providing instant responses 24/7.</p>
                        </div>
                    </div>
                    <div className='col-sm-4 col-md-4 col-xs-12'>
                        <img className="img-fluid relume-img" src={Relume} alt={"Raven AI"} />
                        <h1 className='heading'>Integrate AI with Enterprise Communication Tools</h1>
                        <div className='content-title'>
                            <p className='text'>Enhance collaboration by integrating our AI Agents into platforms like Teams and Slack.</p>
                        </div>

                    </div>
                    <div className='col-sm-4 col-md-4 col-xs-12'>
                        <img className="img-fluid relume-img" src={Relume} alt={"Raven AI"} />
                        <h1 className='heading'>Effortless Transaction Handling with AI Agents</h1>
                        <div className='content-title'>
                            <p className='text'>Streamline transactions, from bookings to cancellations, with our intelligent agents.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='learn-more-btn  d-flex align-items-center justify-content-center mx-3'>Learn More</div> */}
                <div className='text-left'>
                    <div className='btn btn-learn btn-sign-up  d-flex align-items-center justify-content-center' onClick={() => {
                        navigate(MyConstant.keyList.projectUrl + "/contact-us");
                    }}>Contact Us
                    </div>
                </div>
            </div>
        </div>


        <div className='unlock-power-ai-agent p-5'>
            <div className='unlock-power-ai-agent-container'>
                <div className='row mb-5 pb-5'>
                    <div className='unlock-power-ai-agent-image col-sm-6 col-md-6 col-xs-12 d-flex justify-content-center align-items-center'>
                        <img src={RavenAIWhite} className='image img-fluid' alt="unlock-power img" />
                    </div>
                    <div className='col-sm-6 col-md-6 col-xs-12 d-flex justify-content-center align-items-center'>
                        <div className='content-box'>
                            <div className='first-content row'>
                                <div className='col-sm-12 col-md-12 col-xs-12'>
                                    <h1 className='heading'>Unlock the Power of AI Agents for Your Business Success</h1>
                                    <p className='text'>
                                        Raven AI Agents streamline operations, delivering significant cost savings and enhanced efficiency. Experience improved customer satisfaction with 24/7 support that meets diverse needs.
                                    </p>
                                </div>
                            </div>
                            <div className='second-content'>
                                <div className='row'>
                                    <div className='col-sm-6 col-md-6 col-xs-12'>
                                        <h3 className='heading'>Cost Savings</h3>
                                        <p className='text'>Reduce operational costs by automating routine tasks with AI-driven solutions.</p>
                                    </div>
                                    <div className='col-sm-6 col-md-6 col-xs-12'>
                                        <h3 className='heading'>Increased Efficiency</h3>
                                        <p className='text'>Boost productivity by allowing your team to focus on higher-value tasks.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        {/* <div className='client-feedback-box'>
            <div className='client-feedback-box-container'>
                <div className='section-title-box'>
                    <h1 className='header-text'>Client Feedback</h1>
                    <p className='text'>Raven AI has transformed our customer service experience.</p>
                </div>
                <div className='section-content-box'>
                    <div className='row'>
                        <div className='col-sm-6 col-md-6 col-xs-12 '>
                            <div className='star-box'>
                                <div className='star-image'>
                                    <img src={Stars} className='img-star-icon' alt="img" />
                                </div>
                                <div className='star-description'>
                                    <h3 className='header-text'>
                                        "The AI agents have streamlined our operations significantly. Our customers love the instant support they receive!"
                                    </h3>
                                </div>
                            </div>
                            <div className='quote'>
                                <div className='row'>
                                    <div className='col-sm-6 row'>
                                        <div className='col-sm-2'>
                                            <div className='quote-image'>
                                                <img src={qutosImage} className='image' alt="img" />
                                            </div>
                                        </div>
                                        <div className='col-sm-5'>
                                            <h5>Jane Doe</h5>
                                            <p>CEO, Tech Solutions</p>
                                        </div>
                                        <div className='col-sm-1'>
                                            <img src={Divider} className='divider-image-box image ' alt="img" />
                                        </div>
                                        <div className='col-sm-3'>
                                            <div className='web-flow-logo'>
                                                <img src={WebFlow} className='image' alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-sm-6 col-md-6 col-xs-12 my-3'>
                            <div className='star-box'>
                                <div className='star-image'>
                                    <img src={Stars} className='img-star-icon' alt="img" />
                                </div>
                                <div className='star-description'>
                                    <h3>
                                        "Implementing Raven AI was a game-changer for us. The efficiency and accuracy of the agents are unmatched!"
                                    </h3>
                                </div>
                            </div>
                            <div className='quote'>
                                <div className='row'>
                                    <div className='col-sm-6 row'>
                                        <div className='col-sm-2'>
                                            <div className='quote-image'>
                                                <img src={qutosImage} className='image' alt="img" />
                                            </div>
                                        </div>
                                        <div className='col-sm-5'>
                                            <div>
                                                <h5>John Smith</h5>
                                                <p>Manager, Global Corp</p>
                                            </div>
                                        </div>
                                        <div className='col-sm-1'>
                                            <img src={Divider} className='divider-image-box image ' alt="img" />
                                        </div>
                                        <div className='col-sm-3'>
                                            <div className='web-flow-logo'>
                                                <img src={WebFlow} className='image' alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}


        <div className='transform-your-business-ai'>
            <div className='p-5 header1 d-flex align-items-center' style={{ backgroundColor: "#000" }}>
                {/* <img src={RavenBlack_w_1440_h_440} className='transform-image w-100' alt="img" /> */}
                <div className='transform-your-business-ai-content flex-1'>
                    <h1 className='heading text-start'>Transform Your Business with AI</h1>
                    <p className='text text-start'>Discover how our AI Agents can enhance your customer service and streamline operations today.</p>
                    <div className='d-flex'>
                        <div className='learn-more-btn d-flex align-items-center justify-content-center' style={{ marginRight: 10 }} onClick={() => {
                            navigate(MyConstant.keyList.projectUrl + "/contact-us");
                        }}>Contact Us</div>
                        {/* <div className='learn-more-btn d-flex align-items-center justify-content-center' style={{ marginRight: 10 }}>Learn More</div> */}
                        {/* <div className='sign-in-btn d-flex align-items-center justify-content-center'>Get Started</div> */}
                    </div>
                </div>
                <div className='flex-1 text-center'>
                    <img src={RavenBlack} className='image img-fluid' alt="unlock-power img" />
                </div>
            </div>
        </div>

        {/* <div className='container-fluid contact-form-container d-flex align-items-center justify-content-center'>
            <div className='row'>
                <div className='col-sm-4 col-md-4 col-xs-12 col-padding'>
                    <div className='icon m-auto'>
                        <img src={envelope} className='image w-100' alt="img" />
                    </div>
                    <div className='text'>
                        <h2 className='text-center'>Email</h2>
                        <p className='email-info text-center'>Reach out for inquiries or support regarding our AI solutions.</p>
                    </div>
                    <div className='address-info'>
                        <p className='text-center text-decoration-underline'>hello@ravenai.com</p>
                    </div>
                </div>
                <div className='col-sm-4 col-md-4 col-xs-12  col-padding'>
                    <div className='icon m-auto'>
                        <img src={phone} className='image w-100' alt="img" />
                    </div>
                    <div className='text'>
                        <h2 className='text-center'>Phone</h2>
                        <p className='email-info text-center'>Contact us for immediate assistance or to discuss your needs.</p>
                    </div>
                    <div className='address-info'>
                        <p className='text-center text-decoration-underline'>+1 (555) 123-4567</p>
                    </div>
                </div>
                <div className='col-sm-4 col-md-4 col-xs-12  col-padding'>
                    <div className='icon m-auto'>
                        <img src={map} className='image w-100' alt="img" />
                    </div>
                    <div className='text'>
                        <h2 className='text-center'>Office</h2>
                        <p className='email-info text-center'>Visit us at our headquarters for a consultation.</p>
                    </div>
                    <div className='address-info'>
                        <p className='text-center text-decoration-underline'>456 Innovation Ave, Tech City, CA 90001 US</p>
                    </div>
                </div>
            </div>
        </div> */}

    </>
};

export default Home;