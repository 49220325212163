import React from 'react';
import { useNavigate } from "react-router-dom";
import LogoBlack from "../assets/LogoBlack.png"
import LogoWhite from "../assets/LogoWhite.png"
import MyConstant from '../config/MyConstant';
import RavenBlack from "../assets/imges/RavenBlackLogo.png"











const TailoredPage = () => {
    const navigate = useNavigate();
    return <>
        <div className='tailored-ai-solution-container'>
            <div className='header1 d-flex align-items-center' style={{ backgroundColor: "#000" }}>
                {/* <img src={RavenBlack_w_1440_h_440} className='background-image w-100' alt="img" /> */}
                <div className='tailored-ai-solution-content flex-1'>
                    <h5 className='text-start empower-text'>Empower</h5>
                    <h1 className='text-start tailored-content-header'>
                        Tailored AI Solutions
                    </h1>
                    <p className='text-start tailored-content-sub-text'>Unlock your potential with our expert consulting services for customized AI Agent deployment and support.</p>
                    <div className='d-flex'>
                        {/* <div className='learn-more-btn d-flex align-items-center justify-content-center' style={{ marginRight: 10 }}>Learn More</div> */}
                        <div className='learn-more-btn d-flex align-items-center justify-content-center c-poiner' onClick={() => {
                            navigate(MyConstant.keyList.projectUrl + "/contact-us");
                        }}>Contact Us</div>
                    </div>
                </div>
                <div className='flex-1 text-center'>
                    <img className='img-fluid' src={RavenBlack} />
                </div>
            </div>
        </div>

        <div className='container-fluid tailored-business-container'>
            <div className='row justify-content-center align-items-center p-2'>
                <div className='col-sm-6'>
                    <div>
                        <h1 className='heading'>Transform Your Business with Tailored AI Agent Consulting Services</h1>
                        <p className='content'>At Raven AI, we specialize in deploying, customizing, and building AI Agents that meet your specific needs. Our consulting services ensure that you scale efficiently while optimizing workflows and enhancing customer interactions.</p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <h1 className='heading'>Tailored Solutions</h1>
                            <p className='content'>We help identify use cases and streamline processes for maximum efficiency.</p>
                        </div>
                        <div className='col-sm-6'>
                            <h1 className='heading'>Secure Infrastructure</h1>
                            <p className='content'>Our team ensures that your AI systems are secure, traceable, and reliable.</p>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6 text-center'>
                    <img src={LogoBlack} className='img-fluid tailored-business-container-right-image img-bg-clr' alt="img" />
                </div>
            </div>
        </div>


        <div className='container-fluid tailored-accelerate-container'>
            <div className='row justify-content-center align-items-center p-2'>
                <div className='col-sm-6 mb-3'>
                    <div>
                        <h5 className='text-start'>Empower</h5>
                        <h1 className='heading'>Accelerate Your AI Agent Implementation Journey</h1>
                        <p className='content'>At Raven AI, we specialize in helping businesses scale rapidly by identifying the most effective use cases for AI Agents. Our tailored approach ensures that your organization can seamlessly integrate AI solutions into your existing workflows.</p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <h1 className='heading'>50%</h1>
                            <p className='content'>Streamlined processes for maximum efficiency and effectiveness.</p>
                        </div>
                        <div className='col-sm-6'>
                            <h1 className='heading'>50%</h1>
                            <p className='content'>Secure and traceable AI infrastructure for peace of mind.</p>
                        </div>
                    </div>
                    <div className='action-box d-flex'>
                        {/* <div className='learn-more-btn  d-flex align-items-center justify-content-center mx-3'>Learn More</div> */}
                        <div className='d-flex align-items-center justify-content-center'>
                            <button className='btn btn-learn btn-sign-up d-flex align-items-center justify-content-center' onClick={() => {
                                navigate(MyConstant.keyList.projectUrl + "/contact-us");
                            }}>Contact Us
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6 text-center'>
                    <img src={LogoWhite} className='img-fluid tailored-accelerate-container-right-image' alt="img" />
                </div>
            </div>
        </div>
        <div className='container-fluid tranforming-business-container'>
            <div className='text-center'>
                <h5 className='empower-text'>Empower</h5>
                <h1 className='heading'>Transforming Your Business with AI Consulting</h1>
                <p className='content'>At Raven AI, our consulting services are designed to help you seamlessly integrate AI Agents into your operations. We focus on tailoring solutions that meet your unique business needs.</p>
            </div>
            <div className='tranforming-business-row-margin'>
                <div className='row'>
                    <div className='col-sm-4 text-center my-3'>
                        <img src={LogoBlack} className='img-fluid img-bg-clr' alt='img' />
                        <h1 className='heading my-3'>Infrastructure Setup for Optimal Performance</h1>
                        <p className='content my-3'>We establish a robust infrastructure that supports efficient AI deployment.</p>
                    </div>

                    <div className='col-sm-4 text-center my-3'>
                        <img src={LogoBlack} className='img-fluid img-bg-clr' alt='img' />
                        <h1 className='heading my-3'>Process Implementation for Seamless Integration</h1>
                        <p className='content my-3'>Our team guides you in implementing processes that maximize the effectiveness of AI Agents.</p>
                    </div>

                    <div className='col-sm-4 text-center my-3'>
                        <img src={LogoBlack} className='img-fluid img-bg-clr' alt='img' />
                        <h1 className='heading my-3'>Ensuring Security and Traceability in Operations</h1>
                        <p className='content my-3'>We prioritize security and traceability to protect your data and enhance trust.</p>
                    </div>
                </div>
            </div>
            <div className='action-box d-flex align-items-center justify-content-center '>
                {/* <div className='learn-more-btn  d-flex align-items-center justify-content-center mx-3'>Learn More</div> */}
                <div className='d-flex align-items-center justify-content-center'>
                    <button className='btn btn-learn d-flex align-items-center justify-content-center' onClick={() => {
                        navigate(MyConstant.keyList.projectUrl + "/contact-us");
                    }}>Contact Us
                    </button>
                </div>
            </div>
        </div>

        {/* <div className='container-fluid tailor-customer-testimonials-cotainer'>
            <h1 className='text-left heading'>Customer Testimonials</h1>
            <p className='content'>Raven AI transformed our customer service experience!</p>
            <div className='row'>
                <div className='col-sm-4'>
                    <img className='img-fluid my-3' src={Stars} alt='img' />
                    <p className='content'>"The AI agents streamlined our operations beyond expectations."</p>
                    <div className='my-4'>
                        <img className='img-fluid quote-image my-3' src={qutosImage} alt='img' />
                        <h1 className='heading'>Jane Doe</h1>
                        <p className='content'>CEO, Tech Solutions</p>
                        <img className='img-fluid web-flow-logo' src={WebFlow} alt='img' />
                    </div>
                </div>
                <div className='col-sm-4'>
                    <img className='img-fluid my-3' src={Stars} alt='img' />
                    <p className='content'>"Thanks to Raven AI, we scaled rapidly and efficiently."</p>
                    <div className='my-4'>
                        <img className='img-fluid quote-image my-3' src={qutosImage} alt='img' />
                        <h1 className='heading'>John Smith</h1>
                        <p className='content'>Director, Innovate Corp</p>
                        <img className='img-fluid web-flow-logo' src={WebFlow} alt='img' />
                    </div>
                </div>
                <div className='col-sm-4'>
                    <img className='img-fluid my-3' src={Stars} alt='img' />
                    <p className='content'>"The consulting services were invaluable for our growth."</p>
                    <div className='my-4'>
                        <img className='img-fluid quote-image my-3' src={qutosImage} alt='img' />
                        <h1 className='heading'>Alice Johnson</h1>
                        <p className='content'>Manager, Future Tech</p>
                        <img className='img-fluid web-flow-logo' src={WebFlow} alt='img' />
                    </div>
                </div>
            </div>
        </div> */}


        <div className='transform-your-business-ai'>
            <div className='header1 d-flex align-items-center' style={{ backgroundColor: "#000" }}>
                {/* <img src={RavenBlack_w_1440_h_440} className='transform-image w-100' alt="img" /> */}
                <div className='transform-your-business-ai-content flex-1'>
                    <h1 className='heading text-start'>Transform Your Business with AI</h1>
                    <p className='text text-start'>Contact us today to explore tailored AI solutions that elevate your operations and customer experience.</p>
                    <div className='d-flex'>
                        <div className='learn-more-btn d-flex align-items-center justify-content-center' style={{ marginRight: 10 }} onClick={() => {
                            navigate(MyConstant.keyList.projectUrl + "/contact-us");
                        }}>Contact Us</div>
                        {/* <div className='learn-more-btn d-flex align-items-center justify-content-center' style={{ marginRight: 10 }}>Consult</div> */}
                        {/* <div className='sign-in-btn d-flex align-items-center justify-content-center'>Learn More</div> */}
                    </div>
                </div>
                <div className='flex-1 text-center'>
                    <img className='img-fluid' src={RavenBlack} />
                </div>
            </div>
        </div>


    </>
};

export default TailoredPage;